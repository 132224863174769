import React from "react";
import '../../App.css';
import Footer from "../Footer";
import ProdSection from "../ProdSection";

export default function Products() {
    return (
        <>
        <h1 className="products">PRODUCTS</h1>
        <ProdSection/>
        <Footer/>
        </>
    )
}