import React from "react";
import '../../App.css';
import Footer from "../Footer";
import Element from "../Element";


export default function AboutUs() {
    return(
    <>
    <h1 className="about-us">About us</h1>
    <Element/>
    <Footer/>
    </>
    )
}